@import "./theme-dependencies";

* {
  box-sizing: border-box;
}

html,
body {
  border: 0;
  margin: 0;
  padding: 0;
}

body {
  color: $body-fg-color;
  background-color: $body-bg-color;
  font-family: $body-font-family;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
}

img,
svg {
  vertical-align: middle;
}
