$color-black: #121212;
$color-white: #e1e1e1;
$color-gray: #1e1e1e;
$color-gray-light: #9a9a9a;

$color-primary: $color-white;
$color-secondary: $color-gray-light;
$color-bg: $color-gray;

$grid-base: 5px;

$font-family: Arial;
$font-weight: 400;
$font-weight-bold: 600;
$font-base: 4px;

$body-bg-color: $color-black;
$body-fg-color: $color-white;
$body-font-family: $font-family;
$body-font-size: $font-base * 4;
$body-font-weight: $font-weight;
