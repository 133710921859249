@import "../../styles/theme-dependencies.scss";

.placeholder {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  img {
    height: auto;
    width: 256px;
    max-width: calc(75vw);
  }

  p {
    color: $color-white;
    font-size: 12px;
    margin-top: 30px;
    text-transform: uppercase;

    @media screen and (max-width: 350px) {
      font-size: 10px;
    }

    a:link,
    a:active,
    a:visited,
    a:hover {
      color: $color-white;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}
